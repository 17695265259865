<template>
  <div id="paymentCard">
    <div @click="selectPayment(0)" class="paygift_back ffr fw400">
      <i class="icons icon_back payment"></i>
      &nbsp;&nbsp; {{ $t('giftCardView.payment.text11')}}
    </div>
    <form @submit.prevent="validateBeforeCard" id="pay" name="pay">
      <input
        id="transactionAmount"
        name="transactionAmount"
        type="hidden"
        v-model="card.net_amount"
      />
      <div class="img_credit_card">
        <div class="header_credit_card">
          <div>
            <span class="tilte_card ffr fw400">{{ $t('giftCardView.payment.text14')}}</span>
          </div>
          <div class="cont_img_cards">
            <div
              :style="{'background-image' : 'url(img/VISA.svg)'}"
              class="img_card"
              v-if="viewCard == 'VISA'"
            ></div>
            <div
              :style="{'background-image' : 'url(img/MASTERCARD.svg)'}"
              class="img_card"
              v-if="viewCard == 'MASTERCARD'"
            ></div>
            <div
              :style="{'background-image' : 'url(img/DINERS.svg)'}"
              class="img_card"
              v-if="viewCard == 'DINERS'"
            ></div>
            <div
              :style="{'background-image' : 'url(img/AMEX.svg)'}"
              class="img_card"
              v-if="viewCard == 'AMEX'"
            ></div>
          </div>
        </div>
        <div class="content_credit_card">
          <span class="number_card_img ffr fw500">{{cardNumberView}}</span>
          <span
            class="name_card_img ffr fw400"
          >{{ card.payer.first_name ? card.payer.first_name : $t('giftCardView.payment.text34')}}</span>
          <div class="cont_expiration">
            <span
              class="name_card_img ffr fw400"
            >{{mes ? mes : $t('giftCardView.payment.text26')}}/{{ano ? ano : $t('giftCardView.payment.text27')}}</span>
          </div>
        </div>
      </div>
      <div class="cont_text_header">
        <span class="title_paymentCard ffr fw500">
          {{ $t('giftCardView.payment.text13')}}
          <br class="om" />
          {{ $t('giftCardView.payment.text14')}}
        </span>
        <span class="subtitle_paymentCard ffr fw400">
          {{ $t('giftCardView.payment.text15')}}
          <br class="om" />
          {{ $t('giftCardView.payment.text16')}}
        </span>
      </div>
      <div class="cont_form">
        <span class="paycardgift_data buyer ffr fw400">{{ $t('giftCardView.payment.text17')}}</span>
        <div class="group1_card mt20">
          <div class="cont_name">
            <input
              :placeholder="$t('giftCardView.payment.text18')"
              class="input_credit full"
              data-checkout="cardholderName"
              icon
              icon-pack="fa"
              id="cardholderName"
              name="cardholderName"
              type="text"
              v-model="card.payer.first_name"
              v-validate="'required|min:2'"
            />
            <span
              :class="{'no_error' : !errors.has('name')}"
              class="text_error_card ffr fw300"
            >{{ errors.first("name") }}</span>
          </div>
          <div class="cont_email">
            <input
              :placeholder="'Email'"
              class="input_credit full"
              id="email"
              name="email"
              type="email"
              v-model="card.payer.email"
              v-validate="'required|email'"
            />
            <span
              :class="{'no_error' : !errors.has('email')}"
              class="text_error_card ffr fw300"
            >{{ errors.first("email") }}</span>
          </div>
        </div>
        <div class="group2_card">
          <div class="select_type_doc">
            <select
              :placeholder="$t('giftCardView.payment.text36')"
              class="input_credit select_doc full"
              data-checkout="docType"
              expanded
              id="docType"
              name="docType"
              v-model="card.payer.identification.type"
              v-validate="'required'"
            >
              <option :value="null" disabled hidden selected>{{ $t('giftCardView.payment.text36') }}</option>
              <option
                :key="'typeIdenti#' + ide"
                :value="identi.id"
                v-for="(identi, ide) in identification"
              >{{ identi.name }}</option>
            </select>
            <span
              :class="{'no_error' : !errors.has('docType')}"
              class="text_error_card ffr fw300"
            >{{ errors.first("docType") }}</span>
          </div>
          <div class="cont_document">
            <input
              :placeholder="$t('giftCardView.payment.text19')"
              autocomplete="off"
              class="input_credit doc_number full"
              data-checkout="docNumber"
              id="docNumber"
              name="docNumber"
              type="text"
              v-model="card.payer.identification.number"
              v-validate="'required|numeric|min:5'"
            />
            <span
              :class="{'no_error' : !errors.has('docNumber')}"
              class="text_error_card ffr fw300"
            >{{ errors.first("docNumber") }}</span>
          </div>
        </div>
        <div class="group4_card">
          <span class="paycardgift_data ffr fw400">{{ $t('giftCardView.payment.text24') }}</span>
        </div>
        <div class="group5_card mt20 pb15">
          <div class="cont_number_card">
            <div class="input_credit number_card" id="cardNumber"></div>
          </div>
          <div class="cont_cvv">
            <div class="input_credit cvv_card" id="securityCode"></div>
          </div>
        </div>
        <div class="group6_card">
          <div class="cont_cuotas">
            <select
              :placeholder="$t('giftCardView.payment.text28')"
              class="input_credit cuota_card select_custom"
              expanded
              name="cuota"
              v-model="card.installments"
              v-validate="'required'"
            >
              <option
                :value="null"
                disabled
                hidden
                selected
              >{{ $t('giftCardView.payment.text28') }}</option>
              <option
                :key="'cuota#' + y"
                :value="cuota.value"
                v-for="(cuota, y) in cuotas"
              >{{ cuota.label }}</option>
            </select>
            <span
              :class="{'no_error' : !errors.has('cuota')}"
              class="text_error_card ffr fw300"
            >{{ errors.first("cuota") }}</span>
          </div>
          <div class="cont_month_card ml15">
            <div class="input_credit cvv_card" id="expirationDate"></div>
          </div>
        </div>
      </div>
      <div class="cont_btn_pay">
        <div>
          <notifications group="cardNotify" />
        </div>
        <button
          :disabled="loader || errors.items.length > 0"
          class="btn_pay_card ffr fw500"
          type="submit"
        >{{ "Pagar" }}</button>
      </div>
      <div class="pay_terms ffr fw300">
        {{ $t('giftCardView.payment.text29') }}
        <a
          @click="polTerms"
          class="modals_terms"
          href="javascript:void('')"
        >{{ $t('giftCardView.payment.text30') }}</a>
        y
        <a
          @click="polEmmit"
          class="modals_terms"
          href="javascript:void('')"
        >{{ $t('giftCardView.payment.text32') }}</a>
        {{ $t('giftCardView.payment.text33') }}
      </div>
    </form>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
import { mapGetters, mapActions } from 'vuex';
import Cleave from 'cleave.js';
const cleave = {
  name: 'cleave',
  bind (el, binding) {
    const input = el.querySelector('input');
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind (el) {
    const input = el.querySelector('input');
    input._vCleave.destroy();
  }
};
export default {
  props: ['valueGift'],
  directives: { cleave },
  data () {
    return {
      masks: {
        creditCard: { creditCard: false },
        custom: {
          numericOnly: true
        }
      },
      maxNumbers: 19,
      authTerms: true,
      showTerms: false,
      showTerms2: false,
      mes: null,
      ano: null,
      cvv: null,
      cardNumber: null,
      checked: true,
      doSubmit: false,
      card: {
        payer: {
          first_name: null,
          identification: {
            type: null,
            number: null
          },
          email: null
        },
        token: null,
        installments: null,
        description: null,
        payment_method_id: null,
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ]
      },
      identification: [],
      meses: [
        { value: '01', label: '01' },
        { value: '02', label: '02' },
        { value: '03', label: '03' },
        { value: '04', label: '04' },
        { value: '05', label: '05' },
        { value: '06', label: '06' },
        { value: '07', label: '07' },
        { value: '08', label: '08' },
        { value: '09', label: '09' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
      ],
      anos: [],
      cuotas: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' }
      ],
      cuotasDefault: [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' }
      ],
      cardNumberView: null,
      viewCard: null,
      mercadopago: null
    };
  },
  computed: {
    ...mapGetters({
      loader: whiteLabelTypes.getters.loader,
      systemDate: globalTypes.getters.serverTime,
      integrations: [whiteLabelTypes.getters.integrations]
    })
  },
  mounted () {
    const { data } = this.getYears(this.systemDate);
    this.anos = data;
    this.card.transaction_amount = parseInt(this.valueGift);
    this.card.net_amount = parseInt(this.valueGift);
    this.getIdentification();
    this.formatCard();
    if (this.integrations.length > 0) {
      const mercadopago = this.integrations.find(int => int.type === 'mercadopago' && int.environment === process.env.NODE_ENV);
      if (mercadopago) {
        // eslint-disable-next-line
        this.mercadopago = new MercadoPago(this.decryptMx(mercadopago.login));
      } else {
        // eslint-disable-next-line
        this.mercadopago = new MercadoPago(process.env.VUE_APP_MERCADOPAGO);
      }
    } else {
      // eslint-disable-next-line
      this.mercadopago = new MercadoPago(process.env.VUE_APP_MERCADOPAGO);
    }
    this.setConfigMercadoPago();
  },
  methods: {
    ...mapActions({
      confirmGiftCard: reservationTypes.actions.confirmGiftCard
    }),
    setConfigMercadoPago () {
      const cardNumberElement = this.mercadopago.fields.create('cardNumber', {
        placeholder: 'Número de la tarjeta'
      }).mount('cardNumber');
      this.mercadopago.fields.create('expirationDate', {
        placeholder: 'MM/YY'
      }).mount('expirationDate');
      this.mercadopago.fields.create('securityCode', {
        placeholder: 'CVV'
      }).mount('securityCode');
      cardNumberElement.on('binChange', this.getPaymentMethods);
    },
    async getPaymentMethods (data) {
      const { bin } = data;
      if (bin != null && bin !== undefined) {
        const { results } = await this.mercadopago.getPaymentMethods({ bin });
        await this.setPaymentMethod(results);
        await this.getInstallments(bin);
      } else {
        this.card.payment_method_id = null;
        this.maxNumber = 19;
        this.cuotas = this.cuotasDefault;
      }
    },
    async setPaymentMethod (response) {
      let paymentMethodId = response[0].id;
      this.card.payment_method_id = paymentMethodId;
    },
    async getInstallments (bin) {
      const installments = await this.mercadopago.getInstallments({
        amount: document.getElementById('transactionAmount').value,
        bin,
        paymentTypeId: 'credit_card'
      });
      let data = [];
      installments[0].payer_costs.forEach(installment => {
        let opt = {
          label: installment.recommended_message,
          value: installment.installments
        };
        data.push(opt);
      });
      this.cuotas = data;
    },
    setCardTokenAndPay (token) {
      let form = document.getElementById('pay');
      let card = document.createElement('input');
      card.setAttribute('name', 'token');
      card.setAttribute('type', 'hidden');
      card.setAttribute('value', token);
      form.appendChild(card);
      this.doSubmit = true;
      this.validateBeforeCard();
    },
    validateCard () {
      if (this.cardNumber !== null && this.cardNumber.length > 1) {
        let firstDigit = this.cardNumber.substring(0, 1);
        let twoDigits = this.cardNumber.substring(0, 2);
        this.maxNumbers = 19;
        if (firstDigit === '4') {
          this.viewCard = 'VISA';
        }
        if (firstDigit === '5') {
          this.viewCard = 'MASTERCARD';
        }
        if (twoDigits === '34' || twoDigits === '37') {
          this.viewCard = 'AMEX';
        }
        if (twoDigits === '36' || twoDigits === '38') {
          this.maxNumbers = 21;
          this.viewCard = 'DINERS';
        }
        return;
      }
      this.viewCard = null;
    },
    formatCard () {
      let cardNumberTemp = '';
      if (this.cardNumber !== null && this.cardNumber.length > 0) {
        cardNumberTemp = this.cardNumber.replaceAll(' ', '');
        let res = 16 - cardNumberTemp.length;
        for (res; res > 0; res--) {
          cardNumberTemp += '0';
        }
        let cardNumberTemp2 = '';
        for (let i = 0; i < cardNumberTemp.length; i++) {
          if (i % 4 === 0 && i > 0) cardNumberTemp2 = cardNumberTemp2.concat(' ');
          cardNumberTemp2 = cardNumberTemp2.concat(cardNumberTemp[i]);
        }
        this.cardNumberView = cardNumberTemp2;
        return;
      }
      this.cardNumberView = '0000 0000 0000 0000';
    },
    selectPayment (type) {
      this.$emit('payment-type', type);
    },
    async validateBeforeCard () {
      let instance = this;
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (!instance.doSubmit) {
            const token = await this.mercadopago.fields.createCardToken({
              cardholderName: document.getElementById('cardholderName').value,
              identificationType: document.getElementById('docType').value,
              identificationNumber: document.getElementById('docNumber').value
            });
            this.card.token = token.id;
            this.setCardTokenAndPay(token.id);
            return false;
          } else {
            instance.proccessCard();
          }
        }
      });
    },
    proccessCard () {
      this.$notify({ group: 'cardNotify', clean: true });
      this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
      this.confirmGiftCard({
        idGiftCard: window.localStorage.getItem('randonGift'),
        cardCredit: this.card,
        payMethod: 'mp'
      })
        .then((response) => {
          if (response.data.code === 705) {
            this.$emit(
              'card-transaction-result',
              'Transacción Declinada',
              true
            );
          }
          if (response.data.code === 703 || response.data.code === 704) {
            this.$emit(
              'card-transaction-result',
              'Transacción Declinada',
              true
            );
          }
          if (response.data.code === 710 || response.data.code === 711) {
            this.$emit(
              'card-transaction-result',
              'Intenta realizar la reserva de nuevo',
              true
            );
          }
          if (response.data.code === 780) {
            this.$emit('pendingTransactionCard', true);
          }
          if (response.data.code === 200) {
            this.$emit('card-transaction-result', 'ok', false);
          }
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
        })
        .catch(() => {
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          this.$emit(
            'card-transaction-result',
            'Ocurrio un error al procesar la petición con la pasarela de pago',
            true
          );
        });
    },
    guessPaymentMethod (cardnumber) {
      let number = cardnumber.replace(' ', '');
      if (number.length >= 6) {
        let bin = number.substring(0, 6);
        window.Mercadopago.getPaymentMethod(
          {
            bin: bin
          },
          this.setPaymentMethod
        );
      }
    },
    getIdentification () {
      this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          this.identification = data.data;
        }
      });
    },
    changedSelectMes (selected) {
      this.mes = selected[0].value;
    },
    changedSelectAno (selected) {
      this.ano = selected[0].value;
    },
    changedSelectCuota (selected) {
      this.card.installments = selected[0].value;
    },
    polEmmit () {
      this.$emit('open-pol');
    },
    polTerms () {
      this.$emit('open-pol2');
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/GiftCard/paymentCard.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700");
</style>
