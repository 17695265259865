<template>
  <div id="paymentCard">
    <div @click="selectPayment(0)" class="paygift_back ffr fw400">
      <i class="icons icon_back payment"></i>
      &nbsp;&nbsp; {{ $t('giftCardView.payment.text11')}}
    </div>
    <form @submit.prevent="validateBeforeCard">
      <div class="img_credit_card">
        <div class="header_credit_card">
          <div>
            <span class="tilte_card ffr fw400">{{ $t('giftCardView.payment.text12')}}</span>
          </div>
          <div class="cont_img_cards">
            <div
              :style="{'background-image' : 'url(img/VISA.svg)'}"
              class="img_card"
              v-if="viewCard == 'VISA'"
            ></div>
            <div
              :style="{'background-image' : 'url(img/MASTERCARD.svg)'}"
              class="img_card"
              v-if="viewCard == 'MASTERCARD'"
            ></div>
            <div
              :style="{'background-image' : 'url(img/DINERS.svg)'}"
              class="img_card"
              v-if="viewCard == 'DINERS'"
            ></div>
            <div
              :style="{'background-image' : 'url(img/AMEX.svg)'}"
              class="img_card"
              v-if="viewCard == 'AMEX'"
            ></div>
          </div>
        </div>
        <div class="content_credit_card">
          <span class="number_card_img ffr fw500">{{cardNumberView}}</span>
          <span
            class="name_card_img ffr fw400"
          >{{ card.name ? card.name : $t('giftCardView.payment.text34')}}</span>
          <div class="cont_expiration">
            <span
              class="name_card_img ffr fw400"
            >{{card.mes ? card.mes : $t('giftCardView.payment.text26')}}/{{card.ano ? card.ano : $t('giftCardView.payment.text27')}}</span>
          </div>
        </div>
      </div>
      <div class="cont_text_header">
        <span class="title_paymentCard ffr fw500">
          {{ $t('giftCardView.payment.text13')}}
          <br class="om" />{{ $t('giftCardView.payment.text14')}}
        </span>
        <span class="subtitle_paymentCard ffr fw400">
          {{ $t('giftCardView.payment.text15')}}
          <br class="om" />{{ $t('giftCardView.payment.text16')}}
        </span>
      </div>
      <div class="cont_form">
        <span class="paycardgift_data buyer ffr fw400">{{ $t('giftCardView.payment.text17')}}</span>
        <div class="group1_card payu mt20">
          <input
            class="input_credit full"
            id="name"
            name="name"
            :placeholder="$t('giftCardView.payment.text18')"
            type="text"
            v-model="card.name"
            v-validate="'required|min:2'"
          />
          <span
            :class="{'no_error' : !errors.has('name')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("name") }}</span>
        </div>
      </div>
      <div class="group2_card">
        <div class="cont_document payu">
          <input
            class="input_credit doc_number"
            id="identification"
            name="identification"
            :placeholder="$t('giftCardView.payment.text19')"
            type="text"
            v-model="card.identification"
            v-validate="'required|numeric'"
          />
          <span
            :class="{'no_error' : !errors.has('identification')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("identification") }}</span>
        </div>
        <div class="cont_phone">
          <input
            class="input_credit"
            id="phones"
            name="phones"
            :placeholder="$t('giftCardView.payment.text20')"
            type="text"
            v-mask="['##########']"
            v-model="card.phone"
            v-validate="'required|min:10|max:10'"
          />
          <span
            :class="{'no_error' : !errors.has('phones')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("phones") }}</span>
        </div>
      </div>
      <div class="group3_card_payu">
        <div class="cont_address">
          <input
            class="input_credit full"
            id="address"
            name="address"
            :placeholder="$t('giftCardView.payment.text21')"
            type="text"
            v-model="card.address"
            v-validate="'required|min:6'"
          />
          <span
            :class="{'no_error' : !errors.has('address')}"
            class="text_error_card ffr fw300"
          >{{ $t('giftCardView.payment.text22')}}</span>
        </div>
        <div class="cont_city">
          <input
            class="input_credit"
            id="city"
            name="city"
            :placeholder="$t('giftCardView.payment.text23')"
            type="text"
            v-model="card.city"
            v-validate="'required|min:3'"
          />
          <span
            :class="{'no_error' : !errors.has('city')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("city") }}</span>
        </div>
      </div>
      <div class="group4_card">
        <span class="paycardgift_data ffr fw400">{{ $t('giftCardView.payment.text24')}}</span>
      </div>
      <div class="group5_card mt20">
        <div class="cont_number_card payu">
          <input
            :maxlength="maxNumbers"
            class="input_credit number_card"
            id="card"
            name="card"
            pattern="[0-9 ]{14,21}"
            :placeholder="$t('giftCardView.payment.text25')"
            type="text"
            v-mask="['#### #### #### #####']"
            v-model="card.card"
            v-on:keydown.delete="overrideDelete($event)"
            v-validate="'required|credit_card'"
          />
          <span
            :class="{'no_error' : !errors.has('card')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("card") }}</span>
        </div>
      </div>
      <div class="group6_card_payu">
        <div class="cont_month_card">
          <select
            class="input_credit month_card select_custom"
            name="mes"
            :placeholder="$t('giftCardView.payment.text26')"
            v-model="card.mes"
            v-validate="'required'"
          >
            <option :value="null" disabled hidden selected>{{ $t('giftCardView.payment.text26') }}</option>
            <option :key="'mes-#' + i" :value="m.value" v-for="(m, i) in mes">{{ m.label }}</option>
          </select>
          <span
            :class="{'no_error' : !errors.has('mes')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("mes") }}</span>
        </div>
        <div class="cont_year_card">
          <select
            class="input_credit year_card select_custom"
            name="ano"
            :placeholder="$t('giftCardView.payment.text27')"
            v-model="card.ano"
            v-validate="'required'"
          >
            <option :value="null" disabled hidden selected>{{ $t('giftCardView.payment.text27') }}</option>
            <option :key="'ano-#' + i" :value="a.value" v-for="(a, i) in ano">{{ a.label }}</option>
          </select>
          <span
            :class="{'no_error' : !errors.has('ano')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("ano") }}</span>
        </div>
        <div class="cont_cuotas">
          <select
            class="input_credit cuota_card select_custom cuota_payu"
            name="cuota"
            :placeholder="$t('giftCardView.payment.text28')"
            v-model="card.cuota"
            v-validate="'required'"
          >
            <option :value="null" disabled hidden selected>{{ $t('giftCardView.payment.text28') }}</option>
            <option :key="'cuota-#' + y" :value="c.value" v-for="(c, y) in cuota">{{ c.label }}</option>
          </select>
          <span
            :class="{'no_error' : !errors.has('cuota')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("cuota") }}</span>
        </div>
      </div>
      <div class="cont_btn_pay">
        <div>
          <notifications group="cardNotify" />
        </div>
        <button
          :disabled="loader || errors.items.length > 0"
          class="btn_pay_card ffr fw500"
          type="submit"
        >{{ "Pagar" }}</button>
      </div>
      <div class="pay_terms ffr fw300">
        {{ $t('giftCardView.payment.text29') }}
        <a
          @click="polTerms"
          class="modals_terms"
          href="javascript:void('')"
        >{{ $t('giftCardView.payment.text30') }}</a>
        {{ $t('giftCardView.payment.text31') }}
        <a
          @click="polEmmit"
          class="modals_terms"
          href="javascript:void('')"
        >{{ $t('giftCardView.payment.text32') }}</a>{{ $t('giftCardView.payment.text33') }}
      </div>
    </form>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import globalTypes from '@/store/types/global';
import { mapGetters, mapActions } from 'vuex';
export default {
  data () {
    return {
      card: {
        name: null,
        identification: null,
        phone: null,
        address: null,
        city: null,
        card: null,
        mes: null,
        ano: null,
        cuota: null
      },
      maxNumbers: 19,
      mes: [
        { value: '01', label: '01' },
        { value: '02', label: '02' },
        { value: '03', label: '03' },
        { value: '04', label: '04' },
        { value: '05', label: '05' },
        { value: '06', label: '06' },
        { value: '07', label: '07' },
        { value: '08', label: '08' },
        { value: '09', label: '09' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' }
      ],
      ano: [],
      cuota: [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
        { value: '13', label: '13' },
        { value: '14', label: '14' },
        { value: '15', label: '15' },
        { value: '16', label: '16' },
        { value: '17', label: '17' },
        { value: '18', label: '18' },
        { value: '19', label: '19' },
        { value: '20', label: '20' },
        { value: '21', label: '21' },
        { value: '22', label: '22' },
        { value: '23', label: '23' },
        { value: '24', label: '24' },
        { value: '25', label: '25' },
        { value: '26', label: '26' },
        { value: '27', label: '27' },
        { value: '28', label: '28' },
        { value: '29', label: '29' },
        { value: '30', label: '30' },
        { value: '31', label: '31' },
        { value: '32', label: '32' },
        { value: '33', label: '33' },
        { value: '34', label: '34' },
        { value: '34', label: '34' },
        { value: '35', label: '35' },
        { value: '35', label: '35' },
        { value: '36', label: '36' }
      ],
      cardNumberView: null,
      viewCard: null
    };
  },
  computed: {
    ...mapGetters({
      loader: whiteLabelTypes.getters.loader,
      systemDate: globalTypes.getters.serverTime
    })
  },
  mounted () {
    const { data } = this.getYears(this.systemDate);
    this.ano = data;
    this.formatCard();
  },
  methods: {
    ...mapActions({
      confirmGiftCard: reservationTypes.actions.confirmGiftCard
    }),
    validateCard () {
      if (this.card.card !== null && this.card.card.length > 1) {
        let firstDigit = this.card.card.substring(0, 1);
        let twoDigits = this.card.card.substring(0, 2);
        if (firstDigit === '4') {
          this.viewCard = 'VISA';
        }
        if (firstDigit === '5') {
          this.viewCard = 'MASTERCARD';
        }
        if (twoDigits === '34' || twoDigits === '37') {
          this.viewCard = 'AMEX';
        }
        if (twoDigits === '36' || twoDigits === '38') {
          this.viewCard = 'DINERS';
        }
        return;
      }
      this.viewCard = null;
    },
    formatCard () {
      let cardNumberTemp = '';
      if (this.card.card !== null && this.card.card.length > 0) {
        cardNumberTemp = this.card.card.replaceAll(' ', '');
        let res = 16 - cardNumberTemp.length;
        for (res; res > 0; res--) {
          cardNumberTemp += '0';
        }
        let cardNumberTemp2 = '';
        for (let i = 0; i < cardNumberTemp.length; i++) {
          if (i % 4 === 0 && i > 0) cardNumberTemp2 = cardNumberTemp2.concat(' ');
          cardNumberTemp2 = cardNumberTemp2.concat(cardNumberTemp[i]);
        }
        this.cardNumberView = cardNumberTemp2;
        return;
      }
      this.cardNumberView = '0000 0000 0000 0000';
    },
    selectPayment (type) {
      this.$emit('payment-type', type);
    },
    validateBeforeCard () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.card.expiration = this.card.ano + '/' + this.card.mes;
          this.proccessCard();
        }
      });
    },
    proccessCard () {
      this.$notify({ group: 'cardNotify', clean: true });
      this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
      this.confirmGiftCard({
        idGiftCard: window.localStorage.getItem('randonGift'),
        cardCredit: this.card,
        payMethod: 'payU'
      })
        .then(response => {
          if (response.data.code === 705) {
            this.$emit(
              'card-transaction-result',
              'Transacción Declinada',
              true
            );
          }
          if (response.data.code === 703 || response.data.code === 704) {
            this.$emit(
              'card-transaction-result',
              'Transacción Declinada',
              true
            );
          }
          if (response.data.code === 710 || response.data.code === 711) {
            this.$emit(
              'card-transaction-result',
              'Intenta realizar la reserva de nuevo',
              true
            );
          }
          if (response.data.code === 780) {
            this.$emit('pendingTransactionCard', true);
          }
          if (response.data.code === 200) {
            this.$emit('card-transaction-result', 'ok', false);
          }
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
        })
        .catch(() => {
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          this.$emit(
            'card-transaction-result',
            'Ocurrio un error al procesar la petición con la pasarela de pago',
            true
          );
        });
    },
    changedSelectAno (selected) {
      this.card.ano = selected[0].value;
    },
    changedSelectCuota (selected) {
      this.card.cuota = selected[0].value;
    },
    polEmmit () {
      this.$emit('open-pol');
    },
    polTerms () {
      this.$emit('open-pol2');
    },
    overrideDelete (event) {
      let value = event.target.value;
      let char = value[value.length - 1];
      if (isNaN(parseInt(char)) || char === ' ') {
        event.target.value = value.substring(0, value.length - 1);
      }
    }
  },
  watch: {
    'card.card' () {
      const cardJustNumber = this.card.card.split(/\s+/).join('');
      const regex = new RegExp(/^3(?:0[0-5]|[68][0-9])[0-9]{5,}$/);
      if (regex.test(cardJustNumber)) {
        this.maxNumbers = 21;
      } else {
        this.maxNumbers = 19;
      }
      this.validateCard();
      this.formatCard();
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/GiftCard/paymentCard.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700");
</style>
