<template>
  <div id="paymentPse">
    <div @click="selectPayment(0)" class="paygift_back ffr fw400">
      <i class="icons icon_back payment"></i>
      &nbsp;&nbsp; {{ $t('giftCardView.payment.text11') }}
    </div>
    <div class="cont_texts_header">
      <span class="title_pse ffr fw500">{{ $t('giftCardView.payment.text37') }}</span>
      <span class="subtitle_pse ffr fw400">
        {{ $t('giftCardView.payment.text15') }}
        <br class="om" />{{ $t('giftCardView.payment.text16') }}
      </span>
    </div>
    <form @submit.prevent="validateBeforePse">
      <div class="groups_pse g1">
        <select
          class="input_pse select_custom full ffr fw400"
          expanded
          id="bank"
          name="bank"
          :placeholder="$t('giftCardView.payment.text38')"
          v-model="pse.transaction_details.financial_institution"
          v-validate="'required'"
        >
          <option :value="null" disabled hidden selected>{{ $t('giftCardView.payment.text38') }}</option>
          <option
            :key="'bank-#' + b"
            :value="bank.id"
            v-for="(bank, b) in banks"
          >{{ bank.description }}</option>
        </select>
        <span
          :class="{'no_error' : !errors.has('bank')}"
          class="text_error_card ffr fw300"
        >{{ errors.first("bank") }}</span>
      </div>
      <div class="groups_pse g2">
        <input
          class="input_pse full ffr fw400"
          expanded
          id="email"
          name="email"
          :placeholder="$t('giftCardView.payment.text35')"
          type="text"
          v-model="pse.payer.email"
          v-validate="'required|email'"
        />
        <span
          :class="{'no_error' : !errors.has('email')}"
          class="text_error_card ffr fw300"
        >{{ errors.first("email") }}</span>
      </div>
      <div class="groups_pse g3">
        <div class="cont_type_doc">
          <select
            class="input_pse full select_custom ffr fw400"
            expanded
            id="typeDoc"
            name="typeDoc"
            :placeholder="$t('giftCardView.payment.text36')"
            v-model="pse.payer.identification.type"
            v-validate="'required'"
          >
            <option :value="null" disabled hidden selected>{{ $t('giftCardView.payment.text36') }}</option>
            <option
              :key="'bank-#' + tp"
              :value="typeP.id"
              v-for="(typeP, tp) in typePerson"
            >{{ typeP.name }}</option>
          </select>
          <span
            :class="{'no_error' : !errors.has('typeDoc')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("typeDoc") }}</span>
        </div>
        <div class="cont_num_doc">
          <input
            class="input_pse full ffr fw400"
            expanded
            id="identification"
            name="identification"
            :placeholder="$t('giftCardView.payment.text19')"
            type="text"
            v-model="pse.payer.identification.number"
            v-validate="'required|numeric|min:5'"
          />
          <span
            :class="{'no_error' : !errors.has('identification')}"
            class="text_error_card ffr fw300"
          >{{ errors.first("identification") }}</span>
        </div>
      </div>
      <div class="groups_pse g4">
        <div class="subgroups_pse">
          <div class="cont_people first">
            <b-switch
              :value="'individual'"
              class="ffr fw400"
              name="typePeople"
              true-value="individual"
              v-model="pse.payer.entity_type"
              v-validate="'required|included:association,individual'"
            >{{ $t('giftCardView.payment.text40')}}</b-switch>
          </div>
          <div class="cont_people">
            <b-switch
              :value="'association'"
              class="ffr fw400"
              name="typePeople"
              true-value="association"
              v-model="pse.payer.entity_type"
              v-validate="'required|included:association,individual'"
            >{{ $t('giftCardView.payment.text43') }}</b-switch>
          </div>
        </div>
        <span
          :class="{'no_error' : !errors.has('typePeople')}"
          class="text_error_card ffr fw300"
        >{{ errors.first("typePeople") }}</span>
      </div>
      <div class="cont_pay_pse">
        <div style="position: relative;">
          <notifications
            group="pseNotify"
            position="bottom center"
            style="position: unset!important; width: 95%"
          />
        </div>
        <button class="btn_pay_pse ffr fw500" type="submit">{{ $t('giftCardView.payment.text41') }}</button>
      </div>
      <div class="footer_pse">
        <div class="msg_terms ffr fw300">
          {{ $t('giftCardView.payment.text29') }}
          <a
            @click="polTerms"
            class="ffr fw300"
            href="javascript:void('')"
          >{{ $t('giftCardView.payment.text30') }}</a>
          {{ $t('giftCardView.payment.text31') }}
          <a
            @click="polEmmit"
            class="ffr fw300"
            href="javascript:void('')"
          >{{ $t('giftCardView.payment.text32') }}</a>
          {{ $t('giftCardView.payment.text43') }}
        </div>
        <div class="mp_image">
          <div class="text_secure_pay ffr fw3000">{{ $t('giftCardView.payment.text42') }}</div>
          <img src="img/mercadoPago.svg" />
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import { mapActions } from 'vuex';
import _ from 'lodash';
export default {
  props: ['valueGift'],
  data () {
    return {
      banks: [],
      typePerson: [],
      pse: {
        transaction_amount: 0,
        net_amount: 0,
        binary_mode: true,
        taxes: [
          {
            value: 0,
            type: 'IVA'
          }
        ],
        description: null,
        payment_method_id: 'pse',
        payer: {
          email: null,
          entity_type: null,
          identification: {
            type: null,
            number: null
          }
        },
        transaction_details: {
          financial_institution: null
        },
        callback_url: null
      },
      authTerms: true,
      showTerms: false,
      showTerms2: false
    };
  },
  methods: {
    ...mapActions({
      confirmGiftCard: reservationTypes.actions.confirmGiftCard
    }),
    selectPayment (type) {
      this.$emit('payment-type', type);
    },
    validateBeforePse () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.proccessPse(this.card);
        }
      });
    },
    proccessPse () {
      this.$notify({ group: 'pseNotify', clean: true });
      this.$store.commit(whiteLabelTypes.mutations.setLoader, true);
      this.confirmGiftCard({
        idGiftCard: window.localStorage.getItem('randonGift'),
        pse: this.pse,
        payMethod: 'mp'
      })
        .then((response) => {
          if (response.data.code === 200) {
            window.location.href = response.data.url;
          } else {
            this.$emit(
              'pse-transaction-result',
              'Ocurrio un error al procesar la petición con la pasarela de pago',
              true
            );
            this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          }
        })
        .catch(() => {
          this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
          this.$emit(
            'pse-transaction-result',
            'Ocurrio un error al procesar la petición con la pasarela de pago',
            true
          );
        });
    },
    getPaymentOptions () {
      this.$http.get('payment/banks/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          let info = _.find(data.data, { id: 'pse' });
          this.banks = info.financial_institutions;
        } else {
          this.banks = [];
        }
      });
    },
    getIdentification () {
      this.$http.get('payment/identifications/' + this.vendor.id).then(({ data }) => {
        if (data.code === 200) {
          this.typePerson = data.data;
        } else {
          this.typePerson = [];
        }
      });
    },
    polEmmit () {
      this.$emit('open-pol');
    },
    polTerms () {
      this.$emit('open-pol2');
    }
  },
  mounted () {
    this.getPaymentOptions();
    this.getIdentification();
    this.pse.transaction_amount = parseInt(this.valueGift);
    this.pse.net_amount = parseInt(this.valueGift);
    if (process.env.NODE_ENV === 'production') {
      this.pse.callback_url = 'https://' + this.subDomain() + '.precompro.com/giftcard?source_utm=mp';
    } else {
      this.pse.callback_url = 'https://dev.precompro.co/giftcard?source_utm=mp';
    }
  },
  watch: {}
};
</script>
<style lang="scss">
@import "@/assets/styles/GiftCard/paymentPse.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700");
</style>
